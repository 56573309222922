import React from 'react';
import './App.css';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('./img', false, /\.(png|jpe?g|svg|PNG|JPE?G|SVG)$/));
const img_lib = [
    {img_file: "IMG_0938.JPG", img_caption: "Cinnamon star bread"},
    {img_file: "demisec.JPG", img_caption: "Petit Four Demi-Sec"},
    {img_file: "napolean.JPG", img_caption: "Mille-Feuille (French Napoleon)"},
    {img_file: "IMG_1409.JPG", img_caption: "Potato galette with carmelized onions and herbed cheese"},
    {img_file: "fraisent.JPG", img_caption: "Frais entremet"},
    {img_file: "croissants.JPG", img_caption: "Plain, chocolate, and ham and cheese croissants"},
    {img_file: "challah.JPG", img_caption: "Challah"},
    {img_file: "biscuits.JPG", img_caption: "Extra Flaky Buttermilk Biscuits"},
    {img_file: "palmiers.JPG", img_caption: "Palmiers"},
    {img_file: "lemon_danish.JPG", img_caption: "Lemon danish"},
    {img_file: "strip.JPG", img_caption: "Raspberry almond strip tart"},
    {img_file: "eclairs.JPG", img_caption: "Traditional eclairs"},
    {img_file: "sabayon.JPG", img_caption: "Prosecco and limoncello sabayon"},
    {img_file: "IMG_1410.JPG", img_caption: "Chicken and mushroom galette with shallots and bacon"},
    {img_file: "macaron_pack.JPG", img_caption: "Macarons"},
    {img_file: "pain_au_lait.JPG", img_caption: "Pain au Lait"},
    {img_file: "cheesecake.JPG", img_caption: "Raspberry lime cheesecake"},
    {img_file: "fougasse.JPG", img_caption: "Fougasse with fresh rosemary and smoked salt"},
    {img_file: "fraispetitfour.JPG", img_caption: "Petit Four Frais"},
    {img_file: "cream_puffs.JPG", img_caption: "Cream puffs filled with lemon mousseline"},
    {img_file: "turnovers.JPG", img_caption: "Apple turnovers"},
    {img_file: "sticky_bun.JPG", img_caption: "Sticky bun"},
    {img_file: "gruyere_rolls.JPG", img_caption: "Gruyere rolls"},
    {img_file: "maracaibo.JPG", img_caption: "Maracaibo entremet"},
    {img_file: "blueberry_lemon.JPG", img_caption: "Blueberry lemon galette"}
];

let highlights = new Array(img_lib.length).fill('PageGallery-thumb');
highlights[0] = 'PageGallery-thumb-selected';

class App extends React.Component {
    constructor(props) {
	super(props);
	this.switchPage = this.switchPage.bind(this);
	this.switchImage = this.switchImage.bind(this);
	this.nextImage = this.nextImage.bind(this);
	this.prevImage = this.prevImage.bind(this);
	this.state = {
	    imgIndex: 0,
	    viewPage: "home",
	    viewImage: img_lib[0]['img_file'],
	    viewCaption: img_lib[0]['img_caption']
	};
    }
    
    nextImage(currentIndex) {
	let newIndex = currentIndex + 1;
	if (newIndex >= (img_lib.length)) {
	    newIndex = 0;
	}
	this.switchImage(newIndex);
    }
    
    prevImage(currentIndex) {
	let newIndex = currentIndex - 1;
	if (newIndex < 0) {
	    newIndex = (img_lib.length - 1);
	}
	this.switchImage(newIndex);
    }

    updateHighlights(img_index) {
	highlights[this.state.imgIndex] = 'PageGallery-thumb';
	highlights[img_index] = 'PageGallery-thumb-selected';
    }	
    
    switchImage(img_index) {
	this.updateHighlights(img_index);
	this.setState({viewImage: img_lib[img_index]['img_file']});
	this.setState({viewCaption: img_lib[img_index]['img_caption']});
	this.setState({imgIndex: img_index});
    }
    
    switchPage(pageName) {
	this.setState({viewPage: pageName}); 
    }
    
    render() {
	const showPage = this.state.viewPage;
	let page;
	
	switch(showPage) {
	case "contact":
	    page = <PageContact />;
	    break;
	case "classes":
	    page = <PageClasses />;
	    break;
	case "lessons":
	    page = <PageLessons />;
	    break;
	case "events":
	    page = <PageEvents />;
	    break;	    
	case "bio":
	    page = <PageBio />;
	    break;
	case "gallery":
	    page = <PageGallery switchImage={this.switchImage} nextImage={this.nextImage} prevImage={this.prevImage} imgIndex={this.state.imgIndex} />;
	    break;
	default:
	    page = <PageHome />;
	}
	
	return (
		<div className="App">
		<div className="AppHeader">
		
		<div className="AppHeader-item" onClick={this.switchPage.bind(this, 'bio')}> About</div>
		<div className="AppHeader-item" onClick={this.switchPage.bind(this, 'classes')}> Classes</div>
		<div className="AppHeader-item" onClick={this.switchPage.bind(this, 'lessons')}> Lessons</div>
		<div className="AppHeader-item" onClick={this.switchPage.bind(this, 'events')}> Parties</div>
		<div className="AppHeader-item" onClick={this.switchPage.bind(this, 'gallery')}> Photos </div>
		<div className="AppHeader-item" onClick={this.switchPage.bind(this, 'contact')}> Contact </div>
		</div>
 		<div className="AppBody">
		<div className="AppBodyOverlay">
		
		<div className="PagePanel">{page}</div>
		</div>
		</div>
		<div className="AppFooter">
		<div className="AppFooter-item">TWO DESSERTS BAKING - WAKEFIELD, MA</div>
		<div className="AppFooter-item">INSTAGRAM: <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/two_desserts_baking/">@two_desserts_baking</a></div>
		<div className="AppFooter-item">FACEBOOK: <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/TwoDessertsBaking">TwoDessertsBaking</a></div>
		</div>
		</div>
	);
    }
}

function PageContact() {
    return (
	    <div className="PageContact">
	    <div className="Page-title">Contact</div>
            <div className="Page-body">
            <p className="PageContact-header">Two Desserts Baking - Wakefield, MA</p>
            <p>Contact me at</p>
            <div className="PageContact-contact"><span className="PageContact-method">Email:</span> <span className="PageContact-address">steve@twodessertsbaking.com</span></div>
	    <div className="PageContact-contact"><span className="PageContact-method">Phone:</span> <span className="PageContact-address">(781) 222-4152</span></div>
            <p>Follow me at</p>
            <div className="PageContact-contact"><span className="PageContact-method">Instagram:</span> <span className="PageContact-address"><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/two_desserts_baking/">@two_desserts_baking</a></span></div>
            <div className="PageContact-contact"><span className="PageContact-method">Facebook:</span> <span className="PageContact-address"><a target="_blank" rel="noopener noreferrer" href="https://fb.me/TwoDessertsBaking ">@TwoDessertsBaking</a></span></div>
            </div>
	    </div>
    );
}

function PageBio() {
    return (
	    <div className="PageBio">
	       <div className="Page-title">About</div>
            <div className="Page-body">
	    <p className="PageBio-description">Two Desserts Baking helps home bakers of all ages and ability build their skills and confidence. Whether you’re a novice baker eager to get started, an occasional baker trying to master a particular skill or recipe, or an experienced cook convinced you don’t have what it takes to be a good baker, we’re there for you. Through classes, personal/small group lessons, and events/parties, Two Desserts Baking is here to get you past the intimidating reputation and learn to love baking as much as we do!</p>
	    <img className="PageBio-thumb" src={images["thumb-me.jpg"]} alt="portrait-thumb"/>
	    <p className="PageBio-bio">Steve Tulloch graduated college with a teaching certificate, and after a two-decade detour into tech punctuated by over a dozen baking classes at King Arthur Flour in Vermont, he decided to turn his hobby into a career. In 2019 he entered the Pastry Certificate Program at the Cambridge School of Culinary Arts, graduating in 2020 with High Honors in both Academics and Lab. Steve has his ServSafe Food Protection Manager Certification and Allergen Awareness Training, is a member of the United States Personal Chef Association, and is fully insured.</p>

	<p><a href="https://uspca.memberclicks.net" target="_blank" rel="noopener noreferrer"><img src="https://uspca.memberclicks.net/assets/images/USPCAMember.png " alt="Member United States Personal Chef Association" width="136" height="140" /></a></p>
              </div>
	   </div>
    );
}

function PageClasses() {
    return (
	    <div className="PageClasses">
	       <div className="Page-title">Upcoming Classes</div>
            <div className="Page-body">

 	
	    <p className="date">Fall 2024 Programs offered through Wilmington Recreation Department</p>

	    <p><i><a target="_blank" rel="noopener noreferrer" href="https://www.wilmingtonma.gov/sites/g/files/vyhlif5221/f/uploads/2024_fall_-_full_newsletter.pdf">Holiday Baking Workshop</a></i></p>
	    <p>Add some home-baked flair to your holiday season with this four-week
class for bakers of all levels. You'll learn to make holiday staples
such as fluffy dinner rolls perfect for the Thanksgiving table and
festive and oh-so-giftable Christmas Candies like Very Vanilla
Marshmallows and Chocolate Coated English Toffee. Just two days before
Thanksgiving you'll learn to make and work with Pate Brisee, the
perfect crust for many of our favorite pies, and take home enough
dough to make one double crust or two single crust pies for your
Turkey Day feast. Best of all, every week you'll go home with treats
to share!</p>

	    <p className="details">Offered by Wilmington (MA) Recreation Department, taught by Two Desserts Baking<br/>For adults<br/>Tuesday evenings starting 11/12 and ending 12/3, 6:00 to 8:15 PM<br/>WHS Consumer Science Room<br/>$175 per student<br/><a target="_blank" rel="noopener noreferrer" href="https://www.wilmingtonma.gov/sites/g/files/vyhlif5221/f/uploads/2024_fall_-_full_newsletter.pdf">(Click here for info on how to register through the Wilmington (MA) Recreation Department)</a></p>



 	    <p className="date">Fall 2024 Classes offered through The Kitchen (Melrose, MA)</p>
	

<p>We're thrilled to offer Fall classes through The Kitchen at 1 Hurd Street in downtown Melrose!</p>

		    <p><i><a target="_blank" rel="noopener noreferrer" href="https://www.thekitchenmelrose.com/service-page/bread-basics-thanksgiving-dinner-rolls?referral=service_list_widget">Bread Basics: Thanksgiving Dinner Rolls (Adults 14+)</a></i></p>
	    <p>Get an introduction to the basics of yeasted bread as we make these
quick and delicious dinner rolls - a perfect accompaniment to your
Thanksgiving feast. You'll learn how to work with yeast, knead and
shape bread dough, and go home with a dozen warm rolls! Perfect for
ages 14+</p>
	    <p className="details">Offered by The Kitchen, taught by Two Desserts Baking<br/>Thursday, November 21st, 6:00 - 8:30 PM<br/>The Kitchen - 1 Hurd Street, Melrose, MA<br/>From $68<br/>limited to 10 students<br/><a target="_blank" rel="noopener noreferrer" href="https://www.thekitchenmelrose.com/service-page/bread-basics-thanksgiving-dinner-rolls?referral=service_list_widget">Click here to register</a></p>

		    <p><i><a target="_blank" rel="noopener noreferrer" href="https://www.thekitchenmelrose.com/service-page/giftable-holiday-candies?referral=service_list_widget">Giftable Holiday Candies (Adults 14+)</a></i></p>
	    <p>Learn to make two of Chef Steve's family's favorite holiday
confections: Very Vanilla Marshmallows and Chocolate-coated English
Toffee. If you've never tried homemade marshmallows, you'll be blown
away! Both candies make thoughtful and delicious homemade presents for
the holidays. Ages 14+</p>
	    <p className="details">Offered by The Kitchen, taught by Two Desserts Baking<br/>Sunday, December 15th, 3:00 - 5:30 PM<br/>The Kitchen - 1 Hurd Street, Melrose, MA<br/>From $60<br/>limited to 10 students<br/><a target="_blank" rel="noopener noreferrer" href="https://www.thekitchenmelrose.com/service-page/giftable-holiday-candies?referral=service_list_widget">Click here to register</a></p>

 	    <p className="date">Fall 2024 Classes at Food rEvolution (Stoneham, MA)</p>
	

<p>We're thrilled to partner with Food rEvolution to offer classes and
workshops for both kids and adults throughout the Fall at Food
rEvolution at 3 Marble Street (just off of Main Street) in downtown
Stoneham!</p>

 		    <p><i><a target="_blank" rel="noopener noreferrer" href="https://forms.gle/j8oMZCJSgRLg3Q199">Real Maine Whoopie Pies (Adults)</a></i></p>
	    <p>Learn how to make Real Maine Whoopie Pies the way my Real Maine
Grandmother taught me! Authentic Whoopie Pie filling also makes a
great lighter filling for all sorts of cakes and other desserts
featuring fruit. Everyone goes home with plenty of Whoopie Pies to
share.</p>
	    <p className="details">Wednesday, September 25th, 6:30 - 8:30 PM<br/>Food rEvolution 3 Marble Street, Stoneham, MA<br/>$65 per person<br/>limited to 10 students<br/><a target="_blank" rel="noopener noreferrer" href="https://forms.gle/j8oMZCJSgRLg3Q199">Click here to register</a></p>

	
		    <p><i><a target="_blank" rel="noopener noreferrer" href="https://forms.gle/j8oMZCJSgRLg3Q199">Flavors of Fall: Scones (Adults)</a></i></p>
	    <p>Perfect for novice bakers, learn the basics of scone making while
picking up helpful baking tips and tricks along the way. We'll be
baking two different varieties, each with a little Autumn flair, Apple
Cinnamon and Cranberry Oat. You'll go home with plenty of scones to
share.</p>
	    <p className="details">Wednesday, October 23rd, 6:30 - 8:30 PM<br/>Food rEvolution 3 Marble Street, Stoneham, MA<br/>$65 per person<br/>limited to 10 students<br/><a target="_blank" rel="noopener noreferrer" href="https://forms.gle/j8oMZCJSgRLg3Q199">Click here to register</a></p>

	
		    <p><i><a target="_blank" rel="noopener noreferrer" href="https://forms.gle/j8oMZCJSgRLg3Q199">Thanksgiving Pie Dough Workshop (Adults)</a></i></p>
	    <p>Just in time for Thanksgiving, learn to make Pate Brisee, the perfect
crust for many of our favorite pies. You'll pick up plenty of
pie-making tips and tricks as you practice rolling out and working
with this flaky and tender dough. Each baker will go home with a
delicious Galette (a free form pie that can be filled with all sorts
of sweet or savory fillings) as well as some dough to practice with at
home.</p>
	    <p className="details">Wednesday, November 20th, 6:30 - 8:30 PM<br/>Food rEvolution 3 Marble Street, Stoneham, MA<br/>$65 per person<br/>limited to 10 students<br/><a target="_blank" rel="noopener noreferrer" href="https://forms.gle/j8oMZCJSgRLg3Q199">Click here to register</a></p>

	
 		    <p><i><a target="_blank" rel="noopener noreferrer" href="https://forms.gle/j8oMZCJSgRLg3Q199">Back by Popular Demand - Real Maine Whoopie Pies (Adults)</a></i></p>
	    <p>Learn how to make Real Maine Whoopie Pies the way my Real Maine
Grandmother taught me! Authentic Whoopie Pie filling also makes a
great lighter filling for all sorts of cakes and other desserts
featuring fruit. Everyone goes home with plenty of Whoopie Pies to
share.</p>
	    <p className="details">Saturday, December 7th, 2:30 - 4:30 PM<br/>Food rEvolution 3 Marble Street, Stoneham, MA<br/>$65 per person<br/>limited to 10 students<br/><a target="_blank" rel="noopener noreferrer" href="https://forms.gle/j8oMZCJSgRLg3Q199">Click here to register</a></p>



</div></div>);}

function PageLessons() {
    return (
	    <div className="PageLessons">
	       <div className="Page-title">Individual and Small Group Lessons</div>
            <div className="Page-body">
	
	 <p>Whether it’s for a unique date night, because you've just always wanted to learn to bake bread, or as a gift for someone special, Two Desserts Baking brings baking lessons right into your kitchen.</p>
<p>A personal baking lesson makes a great gift for that hard to shop for someone, as a thoughtful thank-you to a friend, colleague, or client or for:</p>
<ul>
<li>Anniversaries</li>
<li>Weddings</li>
<li>Showers</li>
<li>Mothers’ and Fathers’ Day</li>
</ul>
<p>Individual lessons give you one-on-one instruction tailored specifically to your skill level, while small group lessons allow you to spend quality time learning to bake alongside family or friends. Both deliver a personalized, hands-on baking experience to remember. Each lesson includes:</p>
<ul>
<li>An initial consultation to ensure we deliver the best possible experience</li>
<li>Shopping for any necessary ingredients</li>
<li>Set-up beforehand</li>
<li>Clean-up afterwards</li>
</ul>
<p>Contact Steve at steve@twodessertsbaking.com or (781) 222-4152 to discuss your baking lesson – I’m looking forward to baking with you!</p>
              </div>
	   </div>
    );
}

function PageEvents() {
    return (
	    <div className="PageEvents">
	    <div className="Page-title">Birthday Parties, Troop Activities, and Corporate Events</div>
            <div className="Page-body">

	    <p>Whether you’re looking for a birthday party activity, a unique get together for your troop, team, club or group, or a corporate team building event, Two Desserts Baking can help you create a baking-themed event in either your home (for up to 10 active participants) or a local kitchen venue.</p>

	    <p>Typical Birthday parties and troop activities are intended for 6 to 12 kids, last 2 hours, and involve all of the kids working together baking a treat selected by you/your child. For most, each kid goes home with the goodies they baked together as a favor. Recent parties have included such recipes as Chocolate Ganache-filled Cupcakes with Buttercream Frosting, Hot Buttered Soft Pretzels, Ooey Gooey Cinnamon Buns, and Coffee Cake Mufins. I am also happy to suggest all sorts of other recipes depending on your/your child's tastes, interests, and experience with baking! A two hour party typically costs between $225 to $300 (plus the cost of kitchen rental where applicable) depending on the number of kids attending.</p>

<p>Contact Steve at steve@twodessertsbaking.com or (781) 222-4152 to discuss your party or event – I’m looking forward to baking with you!</p>

<p className="Page-testimonial">“My daughter's 8th birthday party experience with Two Desserts Baking was wonderful!  Steve was an exceptional teacher who was patient, encouraging and kind to all of his students.  The sugar cookies that they made were festive and delicious and the successful baking experience has inspired my daughter to attempt more recipes.  I would wholeheartedly recommend Two Desserts Baking for a birthday party/baking lesson.”</p> <p className="Page-testimonial-name">- Dana R., Westford</p>


<p className="Page-testimonial">“We hired Two Desserts Baking to teach our Girl Scouts how to make pretzels.  I was very impressed with how organized Steve was in handling a group of 12 young kids!  I was expecting to have to help a lot but was pleasantly surprised at being able to sit and watch!  They all learned a lot about baking while having fun and then got to bring home a super yummy treat!  We couldn’t have asked for a better troop outing!”</p> <p className="Page-testimonial-name">- Keara E., Westford</p>

<p className="Page-testimonial">“Steve provided a fantastic virtual cooking and baking class series for our employees, and our team members really enjoyed the experience. Steve hosted these online classes during the COVID-19 quarantine, and he took care in selecting recipes that allowed us to create wonderful meals and treats with the ingredients we had on-hand. He was a pleasure to work with and I would recommend his classes to any organization or group.”</p> <p className="Page-testimonial-name">- Robin Morgasen, Copyright Clearance Center</p>
              </div>
	   </div>
    );
}

function PageGallery(props) {
    return (
	    <div className="PageGallery">
	       <div className="Page-title">Photos</div>
               <div className="Page-body">
	          <div className="PageGallery-wrapper">
	          <div className="PageGallery-carousel">
	             <div className="PageGallery-arrow" onClick={props.prevImage.bind(this, props.imgIndex)}> &larr; </div>
                     <img className="Page-image" src={images[img_lib[props.imgIndex]['img_file']]} alt={img_lib[props.imgIndex]['img_caption']} />
	             <div className="PageGallery-arrow" onClick={props.nextImage.bind(this, props.imgIndex)}> &rarr; </div>
	          </div>
	          <div className="PageGallery-caption">{img_lib[props.imgIndex]['img_caption']}</div>
	          </div>
                  <Thumbs switchImage={props.switchImage} />
               </div>
	    </div>
    );
}

function Thumbs(props) {
    return (
	    <div className="PageGallery-thumbs">
            {
		img_lib.map((thumb, key) =>
			    <div key={thumb.key}>
			    <div><img className={highlights[key]} src={images[thumb.img_file]} alt={thumb.img_caption} onClick={props.switchImage.bind(this, key)} /></div>
			    </div>
			   )
            }
	</div>
    );			   
}

function PageHome() {
    return (
	    <div className="PageHome">
	</div>
    );
}

export default App;
